import { post, get } from '../utils/request'

//订单列表
function getOrderList(data) {
    return post('/ci/order/platform_list', data)
}
//订单 派单
function dispatchOrder(data) {
    return post('/ci/order/dispatch', data)
}
//订单 派单
function dispatchOrder2(data, errorIgnore) {
    return post('/ci/order/dispatch2', data, {}, {}, errorIgnore ? errorIgnore : false)
}

//订单 修改维修人员
function editTeamUser(data) {
    return post('/ci/order/editTeamUser', data)
}

//报修新增
function orderRepair(data) {
    return post('/ci/order/platform_repair', data)
}
//订单获取一条数据
function getOrderOne(id) {
    return post(`/ci/order/platform_one/${id}`)
}
//订单编辑
function editOrder(data) {
    return post('/ci/order/edit', data)
}
//项目分页获取
function getOrderItem(data) {
    return post(`/ci/order/item/list`, data)
}
//订单物料查询
function getOrderMaterial(data) {
    return post('/ci/order/part/list', data)
}
//取消订单
function cancelOrder(data) {
    return post(`/ci/order/cancel`, data)
}

//作废订单提交
function orderCancelReview(data) {
    return post(`/ci/order_cancel_review/submit`, data)
}

//派机构
function organDispatch(data) {
    return post(`/ci/order/organ_dispatch`, data)
}
//查看派工后的维修人员
function lookMaintainer(orderId) {
    return get(`/ci/team_dispatch/list_order/${orderId}`)
}
//查看故障图片 //和团队现场照片
// function getOrderImg(data) {
//     return post("/ci/order/attachment/list", data);
// }
//查看故障图片 //和团队现场照片 新
function getOrderImg(data) {
    return post('/ci/order/attachment/list_for_group', data)
}
//订单评价查看
function orderLookComment(data) {
    return post('/ci/order/comment/list', data)
}
//订单支付信息查看
function orderLookPayment(data) {
    return post('/ci/order/payment/list', data)
}
//平台详情的车辆信息修改
function orderEditCustomerVehicle(data) {
    return post('/ci/order/platform_edit_customer_vehicle', data)
}
//平台修改客户和用户车辆的关联关系
function orderEditCustomerUser(data) {
    return post('/ci/order/platform_edit_customer_user_vehicle', data)
}
//工单驳回
function orderReject(data) {
    return post('/ci/order/review_reject', data)
}

//工单审核
function orderReview(data) {
    return post('/ci/order/review', data)
}

//审核历史查看
function orderReviewList(data) {
    return post('/ci/order/review/list', data)
}
// //平台端结算
// function orderPay(data) {
//     return post("/ci/order/platform/settle_accounts", data)
// }
//平台端结算
function orderPay(data) {
    return post('/ci/order/payment/settlement', data)
}
//打印小票
function printFeieyun(data) {
    return post('/ci/order/feieyun_print', data)
}

//三包数据

// 三包列表
function orderThreeGuaranteesList(data) {
    return post('/ci/order_three_guarantees/list', data)
}

// 编辑
function orderThreeGuaranteesUpdate(data) {
    return post('/ci/order_three_guarantees/update', data)
}

//维修历史
function orderHistory(data) {
    return post('/ci/order/history', data)
}

//获取订单日志
function orderLogs(data) {
    return post('/ci/order/progress_log/list', data)
}

//获取订单快照
function operaSnapshot(tag, id) {
    return get(`/ci-message/ci-opera-snapshot/list/${tag}/${id}`)
}

//修改订单回访状态
function editReturnVisitStatus(data) {
    return post('/ci/order/editReturnVisitStatus', data)
}

//其他项目查询
function getOrderOtherItems(data) {
    return post('/ci/order/other-item/list', data)
}

//预领料列表
function getMaintainOrderList(data) {
    return post('/ci/maintain-order/list', data)
}

//物料列表查询
function getStockDetailsFindPage(data) {
    return post('/ci-storage/stock/details/findPage', data)
}

//查询所有物料
function getBaseMaterial(data) {
    return post('/ci-storage/base/material/findPage', data)
}
//新增预领料单
function maintainOrderAdd(data) {
    return post('/ci/maintain-order/insert', data)
}

//获取预领料单
function getMaintainOrderOne(id) {
    return get(`/ci/maintain-order/one/${id}`)
}

//批量新增预领料单维修物料
function maintainOrderMaterialBatchAdd(data) {
    return post('/ci/maintain-order-material/batch/insert', data)
}

//删除领料单维修物料
function maintainOrderMaterialdelete(id) {
    return get(`/ci/maintain-order-material/delete/${id}`)
}

//退料单维修物料列表
function maintainOrderMaterialList(data) {
    return post(`/ci/maintain-order-material/list`, data)
}

//申请退料
function maintainOrderMaterialApplyReturn(id) {
    return get(`/ci/maintain-order-material/apply-return/${id}`)
}

//确定退料
function maintainOrderMaterialConfirmReturn(id) {
    return get(`/ci/maintain-order-material/confirm-return/${id}`)
}

//订单物料回显
function getMaintanMaterialList(data) {
    return post(`/ci/order/maintan-material/list`, data)
}

//物料日志
function maintainOrderMateriallLog(data) {
    return post(`/ci/maintain-order-material/used-record`, data)
}

//物料使用历史
function maintainOrderMateriallHistory(data) {
    return post(`/ci/order/material-history`, data)
}

//申请退料
function maintainOrderMateriallApplyReturn(data) {
    return post(`/ci/maintain-order-material/apply-return`, data)
}

//修改价格
function maintainOrderMateriallApplyEditPrice(data) {
    return post(`/ci/maintain-order-material/edit-price`, data)
}

//退料单列表
function maintainOrderReturnList(data) {
    return post(`/ci/maintain-order/return-list`, data)
}

//获取一条退料单
function maintainOrderReturnOne(id) {
    return get(`/ci/maintain-order/return-one/${id}`)
}

//退料单审核
function maintainOrderReturnReview(data) {
    return post(`/ci/maintain-return-order/review`, data)
}

//退料单通过 入库
function maintainOrderReturnConfirm(id) {
    return get(`/ci/maintain-return-order/confirm/${id}`)
}

//退料单通过 入库
function orderPrintInfo(data) {
    return post(`/ci/order/print-info`, data)
}

//三包详情
function orderThreeGuaranteesDetail(id) {
    return get(`/ci/order_three_guarantees/detail/${id}`)
}

//新增三包
function orderThreeGuaranteesInsert(data) {
    return post(`/ci/order_three_guarantees/insert/one `, data)
}

//编辑三包
function orderThreeGuaranteesUpdateOne(data) {
    return post(`/ci/order_three_guarantees/update/one`, data)
}

//批量删除三包
function orderThreeGuaranteesDelete(data) {
    return post(`/ci/order_three_guarantees/delete`, data)
}

//导出订单
function exportOrderData(data) {
    return post(`/ci-analyze/export/order`, data)
}

//导出订单明细
function exportOrderDetailData(data) {
    return post(`/ci-analyze/export/order/detail`, data)
}

//导出订单明细查询
function exportRecordList(data) {
    return post(`/ci-analyze/export/record/list`, data)
}

//退款列表
function paymentRefundList(data) {
    return post(`/ci/order/payment/refund/list`, data)
}

//退款审核
function paymentRefundDetail(id) {
    return get(`/ci/order/payment/refund/detail/${id}`)
}

//退款审核
function paymentRefundReview(data) {
    return post(`/ci/order/payment/refund/review`, data)
}

//退款同步
function paymentRefundSync(data) {
    return post(`/ci/order/payment/refund/sync `, data)
}

//派单检测
function orderPrepayCheck(orderId) {
    return get(`/ci/order/prepay-check/${orderId}`)
}

//作废审核记录表列表
function orderVCancelReviewPage(data) {
    return post(`/ci/order_cancel_review/page`, data)
}
//作废审核记录审批
function orderVCancelReviewAudit(data) {
    return post(`/ci/order_cancel_review/audit`, data)
}

//请求退单
function orderReturnReq(orderId) {
    return get(`/ci/order/return/req/${orderId}`)
}

//请求退单
function orderReturnApproved(orderId) {
    return get(`/ci/order/return/approved/${orderId}`)
}
//退单列表
function returnOrderList(data) {
    return post(`/ci/order/return/list`, data)
}
// /请求退单
function returnOrderOne(id) {
    return get(`/ci/order/return/one/${id}`)
}

//退单保存
function returnOrderEdit(data) {
    return post(`/ci/order/return/edit`, data)
}

//退单入库
function returnOrderWarehouse(id) {
    return get(`/ci/order/return/warehouse/${id}`)
}

//退单结算
function returnOrderSettlement(data) {
    return post(`/ci/order/return/settlement`, data)
}

//退单三包信息
function returnOrderThreeGuaranteesMaterialOne(data) {
    return post(`/ci/order/return/threeGuarantees/one`, data)
}

//退单三包修改
function returnOrderThreeGuaranteesMaterialEdit(data) {
    return post(`/ci/order/return/threeGuaranteesMaterial/edit`, data)
}

function exportReturnOrderData(data) {
    return post(`/ci-analyze/export/return-order`, data)
}

// 大包组合管理
function orderCombinationList(data) {
    return post(`/ci/order/big-bag/combination/list`, data)
}

// 大包组合管理
function orderCombinationOne(id) {
    return get(`/ci/order/big-bag/combination/one/${id}`)
}

// 大包组合管理
function orderCombinationUpdate(data) {
    return post(`/ci/order/big-bag/combination/update`, data)
}

// 厂家结算单导入记录列表
function factoryThreeGuaranteesRecordList(data) {
    return post(`/ci/factory/three-guarantees/record/list`, data)
}

// 厂家结算单分页
function factoryThreeGuaranteesList(data) {
    return post(`/ci/factory/three-guarantees/list`, data)
}

// 工单分页查询
function factoryThreeGuaranteesOrderList(data) {
    return post(`/ci/factory/three-guarantees/order/list`, data)
}

//获取一条数据
function factoryThreeGuaranteesOne(id) {
    return get(`/ci/factory/order_three_guarantees/one/${id}`)
}

function pamentOrderSettle(data) {
    return post(`/ci/order/payment/platform/settlement`, data)
}

//加盟商工单结算
function pamentOrderFranchiseeSettle(data) {
    return post(`/ci/order/payment/franchisee/settlement`, data)
}
function accountFind(data) {
    return post(`/fms/account/find`, data)
}
// 打印次数
// function printNum(id) {
//     return get(`/ci/order/print/count/${id}`)
// }
function printNum(data) {
    return post(`/ci/order/print-count`, data)
}
/**
 * 上传维附件
 * @param {object} data 参数
 */
function OrderUploadFile(data) {
    return post('/ci/order/attachment/insert', data)
}
/**
 * 删除附件
 * @param {string} id 参数
 * @param {object} data 参数
 */
function OrderDeleteFile(id, data) {
    return get(`/ci/order/attachment/delete/${id}`, data)
}
/**
 * 上传维附件
 * @param {object} data 参数
 */
function OrderUploadImages(data) {
    return post('/ci/order/attachment/batch_insert', data)
}

//获取维退数据
function returnOrderPrintOne(id) {
    return post(`/ci/order/return/print/info/${id}`)
}
// 维退打印次数
function returnOrderPrintNum(id){
    return get(`/ci/order/return/print/count/${id}`)
}


// 维退打印次数
function factoryThreeGuaranteesExport(id){
    return get(`/ci-analyze/factory/three-guarantees/export/${id}`)
}

// 采埃孚数据字典
function zfDict(){
    return get(`/ci/zf/dict`)
}


// 采埃孚数据字典
function orderReportOne(orderId){
    return get(`/ci/order/report/one/${orderId}`)
}

// 是否是待派单订单
function orderPispatchPlanOrderInfo(orderId){
    return get(`/ci/order/dispatch-plan/order-info/${orderId}`)
}








export {
    orderReportOne,
    returnOrderPrintOne,
    returnOrderPrintNum,
    OrderUploadFile,
    OrderDeleteFile,
    OrderUploadImages,
    getBaseMaterial,
    getOrderList,
    getOrderOne,
    getOrderImg,
    getOrderItem,
    getOrderMaterial,
    editOrder,
    dispatchOrder,
    cancelOrder,
    orderRepair,
    orderLookComment,
    orderLookPayment,
    lookMaintainer,
    orderEditCustomerVehicle,
    orderEditCustomerUser,
    orderReject,
    orderReviewList,
    orderPay,
    printFeieyun,
    organDispatch,
    orderThreeGuaranteesList,
    orderThreeGuaranteesUpdate,
    dispatchOrder2,
    editTeamUser,
    orderHistory,
    orderLogs,
    operaSnapshot,
    editReturnVisitStatus,
    getOrderOtherItems,
    getMaintainOrderList,
    getStockDetailsFindPage,
    maintainOrderAdd,
    getMaintainOrderOne,
    maintainOrderMaterialBatchAdd,
    maintainOrderMaterialdelete,
    maintainOrderMaterialList,
    maintainOrderMaterialApplyReturn,
    maintainOrderMaterialConfirmReturn,
    getMaintanMaterialList,
    maintainOrderMateriallLog,
    orderReview,
    maintainOrderMateriallHistory,
    maintainOrderMateriallApplyReturn,
    maintainOrderReturnList,
    maintainOrderReturnOne,
    maintainOrderReturnReview,
    maintainOrderReturnConfirm,
    orderPrintInfo,
    orderThreeGuaranteesDetail,
    orderThreeGuaranteesInsert,
    orderThreeGuaranteesUpdateOne,
    orderThreeGuaranteesDelete,
    exportOrderData,
    exportOrderDetailData,
    exportRecordList,
    paymentRefundList,
    paymentRefundReview,
    paymentRefundSync,
    paymentRefundDetail,
    orderPrepayCheck,
    orderCancelReview,
    orderVCancelReviewPage,
    orderVCancelReviewAudit,
    orderReturnReq,
    orderReturnApproved,
    returnOrderList,
    returnOrderOne,
    returnOrderEdit,
    returnOrderWarehouse,
    returnOrderSettlement,
    returnOrderThreeGuaranteesMaterialOne,
    returnOrderThreeGuaranteesMaterialEdit,
    maintainOrderMateriallApplyEditPrice,
    exportReturnOrderData,
    orderCombinationList,
    orderCombinationOne,
    orderCombinationUpdate,
    factoryThreeGuaranteesRecordList,
    factoryThreeGuaranteesList,
    factoryThreeGuaranteesOrderList,
    factoryThreeGuaranteesOne,
    pamentOrderSettle,
    accountFind,
    printNum,
    factoryThreeGuaranteesExport,
    zfDict,
    pamentOrderFranchiseeSettle,
    orderPispatchPlanOrderInfo
}
