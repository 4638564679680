import React, { useState } from "react";
import { createModel } from "hox";
import useStorageState from "../hooks/useStorageState";
import { customerService } from "@/erp_subpackage/pages/base/customer/customerService"
import { supplierService } from "@/erp_subpackage/pages/base/supplier/supplierService"
import { warehouseService } from "@/erp_subpackage/pages/base/warehouse/warehouseService"
import { getCustomerListNew } from "@/service/customerService"
import { commonService } from "@/erp_subpackage/pages/common/commonService";
import {message} from "antd"
import { IVRMSettleTypeEnum, UserTypeEnum } from '@/erp_subpackage/utils/enum'

export function transPgToPagination(pg) {
    return {
        total: pg.total,
        pageSize: pg.size??pg.pageSize,
        current: pg.current ?? pg.pageNum
    }
}

//云创通用字段定义
export const defaultMdCmParam = {
    sourceApp: "ci",
    branch: "ci",
    sourceAuthKey: "",
    ver: undefined,
    enable: 1,
};

export const branchDs = {
    "vms": "整车管理系统",
    "pms": "生产管理系统",
    "bcm": "客车管理系统",
    "vrm": "云修系统",
    "ci": "云豪车服",
    "scm": "供应链系统",
    "vrm-nga": "云修尼日",
    "vrm-sa": "云修沙特",
}
const initUserState = {
    id: "",
    userName: "",
    password: "",
    token: "",
    shopId: "",
    shopName: "",
    shopCode: "",
    orgCode: "yh.service.app",
    orgName: "云豪车服APP",
    bentityName: undefined,  //经营实体code
    bentityCode: undefined,  //经营实体名称
    organType: false,   //判断登陆是否自营性质的机构，用于查看所有机构
    allowTransfer: false,   //用户的调拨权限
    businessEntityCode: "",   //用户关联的实体
    businessEntityName: "",
    orgShopCode: "",   //用户关联的门店
    orgShopName: "",
}
export const getFindItem = (code, list) => {
    return list.find(item => item.code === code)
}
function useModel() {
    //用户所有信息
    const [user, setUser, resetUser] = useStorageState('ci.user', initUserState);
    //设置展开与合并
    const [collapsed, setCollapsed] = useState(false)
    //对应门店所有仓库
    const [whDs, setWhDs] = useStorageState("ci.whDs", []);
    //云创所有机构
    const [orgDs, setOrgDs] = useStorageState("ci.orgDs", []);
    //所有内部核算单位
    const [accUnitDs, setAccUnitDs] = useStorageState("ci.acctUnit", [])
    //商品采销历史 通用弹框
    const [materialDialog, setMaterialDialog] = useState({
        visible: false,
        materialCode: null,
        materialName: null,
        customerName: null,
        customerCode: null,
    });

    const setMaterialInfo = (visible, materialInfo) => {
        // debugger
        if (!visible || !materialInfo || !materialInfo.materialCode) return setMaterialDialog({ visible: false });
        setMaterialDialog({
            ...materialInfo,
            visible
        })
    }
    //占用库存
    const [occupyStockDialog, setOccupyStockDialog] = useState({
        visible: false,
        materialCode: null,
        branch: "ci",
    })
    const setOccupyStockInfo = (visible, materialInfo) => {
        if (!visible || !materialInfo.materialCode) return setOccupyStockDialog({ visible: false });
        setOccupyStockDialog({
            ...materialInfo,
            visible
        })
    }
    //待入库库存
    const [waitStockDialog, setWaitStockDialog] = useState({
        visible: false,
        materialCode: null,
    })
    const setWaitStockInfo = (visible, materialInfo) => {
        if (!visible || !materialInfo.materialCode) return setWaitStockDialog({ visible: false });
        setWaitStockDialog({
            ...materialInfo,
            visible
        })
    }
    //全局loading
    const [loadingInfo, setLoadingInfo] = useState({
        loadingVisible: false,
        txt: ""
    });

    //实时查询供应商接口
    const fetchSupplierList = async (value) => {
        let data = { pageSize: 15, pageNum: 1, shopId: user.shopId, shopCode: user.shopCode, name: value?.trim() ?? "", enable: 1 };
        return supplierService.page(data).then(res => {
            const { retData: { records } } = res;
            console.log('111',records)
            return records ? records.map(item => ({
                id: item.id,
                label: item.name,
                value: item.code,
                contact: item.contact ?? item.name2,
                phone: item.phone ?? item.phone2,
                type_code: item.categoryCode,
                type_name: item.categoryName,
                name2: item.name2,
                phone2: item.phone2
            })) : [];
        })
    }

    //实时查询客户接口(新增走自己的客户)
    const fetchCustomerList = async (value) => {
        let data = {
            pageSize: 15,
            pageNum: 1,
            shopId: user.shopId,
            shopCode: user.shopCode,
            customerName: value?.trim() ?? "",
            enable: 1
        };
        return customerService.page(data).then(res => {
            const { retData: { records } } = res;
            return records ? records.map(item => ({
                key: item.id,
                id: item.id,
                label: item.name,
                value: item.code,
                contact: item.contact,
                phone: item.phone,
                address: item.address,
                category_code: item.categoryCode,
                category_name: item.categoryName,
            })) : [];
        })
    }

    //实时查询客户接口(小程序和App走云创本身的客户)
    const fetchYCCustomerList = async (value) => {
        let data = {
            pageSize: 15,
            pageNum: 1,
            orders: [
                {
                    column: "createTime",
                    rule: "DESC",
                },
            ],
            // shopId: user.shopId,
            searchItem: { customerName: value?.trim() ?? "",  customerStatus: 0},
           
        };
        return getCustomerListNew(data).then(res => {
            const { retData: { list } } = res;
            return list ? list.map(item => ({
                key: item.id,
                id: item.id,
                label: item.customerName,
                value: item.customerCode,
                contact: item.customerCorporation,
                phone: item.customerCorporationPhone,
                address: item.customerAddress,
                category_code: item.customerType,
            })) : [];
        })
    }

    /**
     * 实时查询财务的门店  
     * isFmsShop 是否查询财务的门店
     */
    const fetchFmsShopList = async (value, isFmsShop) => {
        let list = [];
        let { retData } = await commonService.orgPageList({
            "organName": value?.trim() ?? "",
        });
        retData && retData.forEach(item => {
            //有调拨权限的
            if (item.allowTransfer === 1 && item.mdShopId) {
                list.push({
                    key: item.mdShopId + '--' + defaultMdCmParam.sourceApp,
                    //code: item.organCode ? item.organCode : item.id,
                    code: item.shopCode ? item.shopCode : item.id,
                    label: <div className="flex-between"><span>{item.organName}</span><span style={{ fontSize: "12px" }}>{branchDs[defaultMdCmParam.sourceApp] ?? ""}</span></div>,
                    name: item.organName,
                    value: item.mdShopId + '--' + defaultMdCmParam.sourceApp,
                    branch: defaultMdCmParam.sourceApp,
                    id: item.mdShopId
                })
            }
        })
        if (isFmsShop) {
            let data = { page: { pageSize: 15, pageNum: 1 }, sourceApp: "fms",status:0, name: value?.trim() ?? "" };
            let { retData: { records } } = await commonService.pageFms(data);
            records && records.forEach(item => {
                //相同分支 属于内部调拨， 不查询云创分支的门店
                if (item.branch !== "ci") {
                    list.push({
                        key:  item.id + '--' + item.branch,
                        code: item.code,
                        label: <div className="flex-between"><span>{item.name}</span><span style={{ fontSize: "12px" }}>{branchDs[item.branch] ?? ""}</span></div>,
                        name: item.name,
                        value: item.id + '--' + item.branch,
                        branch: item.branch,
                        id: item.id
                    })
                }
            })
        }
        let newData = { sourceApp: "fms",status:0, code: value?.trim() ?? "" };
        let { retData: newRecords } = await commonService.newPageFms(newData);
        newRecords && newRecords.forEach(item => {
            //相同分支 属于内部调拨， 不查询云创分支的门店
            if (item.branch !== "ci") {
                list.push({
                    key:  item.id + '--' + item.branch,
                    code: item.code,
                    label: <div className="flex-between"><span>{item.name}</span><span style={{ fontSize: "12px" }}>{branchDs[item.branch] ?? ""}</span></div>,
                    name: item.name,
                    value: item.id + '--' + item.branch,
                    branch: item.branch,
                    id: item.id
                })
            }
        })
        return list.filter((item)=>item.code!=='SHOP-M-00000004');
    }
    const fetchFmsShopNameList = async (value, isFmsShop) => {
        let list = [];
        let { retData } = await commonService.orgPageList({
            "organName": value?.trim() ?? "",
        });
        retData && retData.forEach(item => {
            //有调拨权限的
            if (item.allowTransfer === 1 && item.mdShopId) {
                list.push({
                    key: item.mdShopId + '--' + defaultMdCmParam.sourceApp,
                    //code: item.organCode ? item.organCode : item.id,
                    code: item.shopCode ? item.shopCode : item.id,
                    label: <div className="flex-between"><span>{item.organName}</span><span style={{ fontSize: "12px" }}>{branchDs[defaultMdCmParam.sourceApp] ?? ""}</span></div>,
                    name: item.organName,
                    value: item.organName,
                    branch: defaultMdCmParam.sourceApp,
                    id: item.mdShopId
                })
            }
        })
        if (isFmsShop) {
            let data = { page: { pageSize: -1, pageNum: 1 }, sourceApp: "fms",status:0, name: value?.trim() ?? "" };
            let { retData: { records } } = await commonService.pageFms(data);
            records && records.forEach(item => {
                //相同分支 属于内部调拨， 不查询云创分支的门店
                if (item.branch !== "ci") {
                    list.push({
                        key:  item.id + '--' + item.branch,
                        code: item.code,
                        label: <div className="flex-between"><span>{item.name}</span><span style={{ fontSize: "12px" }}>{branchDs[item.branch] ?? ""}</span></div>,
                        name: item.name,
                        value: item.name,
                        branch: item.branch,
                        id: item.id
                    })
                }
            })
        }
        let newData = { sourceApp: "fms",status:0, code: value?.trim() ?? "" };
        let { retData: newRecords } = await commonService.newPageFms(newData);
        newRecords && newRecords.forEach(item => {
            //相同分支 属于内部调拨， 不查询云创分支的门店
            if (item.branch !== "ci") {
                list.push({
                    key:  item.id + '--' + item.branch,
                    code: item.code,
                    label: <div className="flex-between"><span>{item.name}</span><span style={{ fontSize: "12px" }}>{branchDs[item.branch] ?? ""}</span></div>,
                    name: item.name,
                    value: item.name,
                    branch: item.branch,
                    id: item.id
                })
            }
        })
        return list;
    }
    //初始所有仓库
    const loadWarehouseAllData = async (shopId = user.shopId) => {
        const params = {
            pageSize: 10000,
            pageNum: 1,
            enable: 1,
            shopId,
        }
        let { retData } = await warehouseService.page(params);
        const { records, ...pg } = retData;
        setWhDs(records?.map(item => ({
            id: item.id,
            label: item.name,
            value: item.code,
        })) ?? [])
    }

    //查询所有云创所属机构
    const loadOrgAllData = async () => {
        let { retData } = await commonService.orgPageList({});
        let newList = []
        retData = retData || []
        retData.forEach(item => {
            if(item.mdShopId){
                newList.push({
                    label: item.organName,
                    value: item.mdShopId,
                    code: item.organCode,
                    address: item.organContactAddress,
                    contact: item.organContactName,
                    phone: item.organContactPhone,
                    organ_type: item.organType,
                    organ_nature: item.organNature  //机构性质
                    // disabled: item.organType === 3  //平台账号 下拉框不可以使用
                })
            }
        });
        //增加全部机构
        newList.push({
            label: "全部机构",
            value: "",
        })
        setOrgDs(newList);
    }


    //所有内部核算单位接口
    const accUnitDataAll = async () => {
        const searchParams = {
            shopId: user.shopId,
            pageSize: -1,
            pageNum: 1,
        }
        let { retData } = await commonService.accountUnitList(searchParams);
        const { records, ...pg } = retData;
        setAccUnitDs(records)
    }

    //所有缓存加载与重置
    const requestCatchAll = (shopId = user.shopId) => {
        loadWarehouseAllData(shopId);
        loadOrgAllData();
        accUnitDataAll()
    }

    //根据结算方 查回对应的账户名称，可用额度，是否有账单，客户名称 并返回
    const getSettlementParty = async (res) => {
        let { settlementPartyCode, customerName, customerCode, payType, settlementsettlementPartyParams, bentityCode } = res
        let { shopName, shopId,organId } = user;
        let accountName = "", availableAmount = 0, isBill = false, accountCustomerName = "";
        if (settlementPartyCode === "settle-party.internal") {//这里为部门内部结算时，查询销售实体为客户的接口换取id
            const searchData = {
                pageSize: 15,
                pageNum: 1,
                orders: [
                    {
                        column: 'createTime',
                        rule: 'DESC',
                    },
                ],
                // shopId: shopId,
                // organId:organId,
                searchItem: { customerName: shopName },
                customerStatus: 0,
            }
            await getCustomerListNew(searchData).then(async (res) => {
                let { list } = res.retData
                // console.log(records)
                // debugger;
                if (list.length) {
                    let { customerCode: code, customerName: name } = list.find((item)=>(item.customerName==shopName))
                    await getCustomerBillAndAccount(code, payType, bentityCode).then((res) => {
                        accountName = res.accountName;
                        availableAmount = res.availableAmount;
                        isBill = res.isBill
                    })
                    accountCustomerName = name
                } else {
                    // message.warning(`该结算方为部门内部费用时，无维护对应为${shopName}客户`)
                    message.warning(`该“结算方”为“部门内部费用”时，没有找到“${shopName}”的客户。
                    请对该客户维护后，进行操作！`)
                }
            })
            // } else if (settlementPartyCode?.indexOf("man-cn") !== -1 ||
            //     settlementPartyCode?.indexOf("scania-cn") !== -1 ||
            //     settlementPartyCode?.indexOf("sdec") !== -1 ||
            //     settlementPartyCode?.indexOf("hande") !== -1 ||
            //     settlementPartyCode?.indexOf("xcqc") !== -1 ||
            //     settlementPartyCode?.indexOf("weichai") !== -1 ||
            //     settlementPartyCode?.indexOf("fast") !== -1 ||
            //     settlementPartyCode?.indexOf("saic-iveco-hy") !== -1 ||
            //     settlementPartyCode?.indexOf("voith") !== -1
            //     ) {
        } else if (!(settlementPartyCode === "settle-party.internal" || settlementPartyCode === "settle-party.self-pay" || settlementPartyCode === "settle-party.dabaomaintenance")) {
            let { value: customerCode, name } = settlementsettlementPartyParams
            if(!customerCode){
                message.error(`${name}没有绑定客户编码`)
                accountName = ""
                availableAmount = ""
                isBill = ""
                accountCustomerName = ""
            }else{
                await getCustomerBillAndAccount(customerCode, payType, bentityCode).then((res) => {
                    accountName = res.accountName;
                    availableAmount = res.availableAmount;
                    isBill = res.isBill
                })
                accountCustomerName = name
            }
        } else {
            await getCustomerBillAndAccount(customerCode, payType, bentityCode).then((res) => {
                accountName = res.accountName;
                availableAmount = res.availableAmount;
                isBill = res.isBill
            })
            accountCustomerName = customerName
        }
        return { accountName, availableAmount, isBill, accountCustomerName }
    }

    const getCustomerBillAndAccount = async (partnerCode, payType, bentityCode) => {//参数partnerCode为客户编码 payType为结算类型
        let { shopCode } = user
        //根据结算类型过滤出对应的账户类型
        let accountCode = payType === IVRMSettleTypeEnum.SETTLE_CREDIT ? "account-type.credit" : IVRMSettleTypeEnum.SETTLE_MONTHLY ? "account-type.credit-month" : ""
        let billParams = {
            page: { pageSize: -1, pageNum: 1 },
            shopCode,
            beCode: bentityCode,
            partnerTypeCode: UserTypeEnum.CUSTOMER,
            partnerCode,
            status: 0,
        }//账单搜索参数
        let accountParams = {
            shopCode,
            partnerCode,
            beCode: bentityCode,
        }//账户搜索参数
        let { retData: accountData } = await commonService.accountFind(accountParams);
        let { retData: { records: billData } } = await commonService.bilPage(billParams);
        let newAccount = accountData.find(item => item.accountTypeCode == accountCode)
        let newBill = billData.filter(item => item.accountTypeCode == accountCode)
        return {
            accountName: newAccount?.name,
            availableAmount: newAccount?.availableAmount ?? 0,
            isBill: newBill.length > 0
        }
    }

    return {
        user, orgDs, accUnitDs,
        setUser,
        resetUser,
        loadingInfo,
        setLoadingInfo,
        supplierService,
        fetchCustomerList,
        fetchSupplierList,
        fetchFmsShopList,
        fetchFmsShopNameList,
        requestCatchAll,
        collapsed, setCollapsed,
        whDs,
        fetchYCCustomerList,
        materialDialog, setMaterialInfo,
        loadWarehouseAllData,
        occupyStockDialog, setOccupyStockInfo,
        waitStockDialog, setWaitStockInfo,
        getSettlementParty
    };
}

export const useGlobalModel = createModel(useModel)
export default useGlobalModel;