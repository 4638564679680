import { useState, useRef, useEffect } from 'react'
import { createModel } from 'hox'
import { orderPispatchPlanOrderInfo, dispatchOrder2 } from '@/service/ordersService'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { API_SERVER_HOST } from '@/config/app'
import { getToken } from '@/utils/dataStorage'
import { message } from 'antd'
import { useHomeDispatchInfoModel } from './homeDispatchInfo'
import { getTeamOne } from '@/service/teamService'
import { getUserOrgan } from '@/utils/dataStorage'
const defaultAutoDispatchTime = 5
const fetchEventSourceReq = new AbortController()
export const useHomePengddingOrderModel = createModel(function () {
    const { form } = useHomeDispatchInfoModel()
    //是否有待派单的订单再处理中
    const [isHasPengddingOrderProce, setIsHasPengddingOrderProcess] = useState(false)
    //正在派单的订单
    const pengddingOrderRef = useRef(null)
    //待派单的订单
    const [penddingOrders, setpengdingOrders] = useState([])
    //移除地图的订单id
    const [removeMarkerPengddingOrder, setRemoveMarkerPengddingOrder] = useState('')
    //添加待派单的订单
    const handleAddPenddingOrders = (orderId) => {
        setpengdingOrders([...penddingOrders, orderId])
    }
    //移除待派单的订单
    const handleRemovePenddingOrders = (orderId) => {
        setpengdingOrders(penddingOrders.filter((i) => i !== orderId))
    }

    //获取订单详情
    const getOrderDetail = (orderId) => {
        pengddingOrderRef.current = orderId
        orderPispatchPlanOrderInfo(orderId).then((res) => {
            const { orderStatus } = res.retData
            //判断订单是待派单状态
            if (orderStatus === 'pending_order') {
                setStartPopupVisible(true)
                fetchDify(orderId)
            } else {
                handleRemoveMapOrderIcon()
                closeProcess()
            }
        })
    }

    //点图icon点击
    const handleMapOrderIconClick = (orderId) => {
        if (isHasPengddingOrderProce) {
            return message.error('有订单正在派单中')
        }
        getOrderDetail(orderId)
    }

    //弹窗控制
    //订单开始的弹窗
    const [startPopupVisible, setStartPopupVisible] = useState(false)
    //选择派单方式弹窗
    const [chooseDispatchTypePopupVisible, setChooseDispatchTypePopupVisible] = useState(false)
    //派单的弹窗
    const [dispatchPopupVisible, setDispatchPopupVisible] = useState(false)
    //成功的弹窗
    const [successPopupVisible, setSuccessPopupVisible] = useState(false)

    //智能推送的信息
    const [difyInfoMsg, setFifyInfoMsg] = useState('')
    const [difyInfoJson, setFifyInfoJson] = useState(null)
    const [difyLoading, setFifyLoading] = useState(false)
    //倒计时
    const [autoDispatchTime, setAutoDispatchTime] = useState(0)
    const [autoDispatchTimeIsCounting, setautoDispatchTimeIsCounting] = useState(false)

    //dify信息改变
    const changeDifyInfo = (info) => {
        const { data, loading, answerJson } = info
        setFifyInfoMsg(data)
        setFifyInfoJson(answerJson ? answerJson : {})
        setFifyLoading(loading)
        //loading结束且有json数据
        if (answerJson && !loading) {
            setTimeout(() => {
                handleOpenChooseDispatchTypeVisible(answerJson)
            }, 6000);
        }
    }

    useEffect(() => {
        let timerId

        if (autoDispatchTimeIsCounting) {
            if (autoDispatchTime > 0) {
                timerId = setTimeout(() => {
                    setAutoDispatchTime((prevCountdown) => prevCountdown - 1)
                }, 1000)
            } else {
                // 倒计时结束，重置状态
                setautoDispatchTimeIsCounting(false)
                handleToAutoDispatch()
            }
        }

        return () => clearTimeout(timerId)
    }, [autoDispatchTime, autoDispatchTimeIsCounting])

    //自动派单
    const handleToAutoDispatch = () => {
        clearAutoDispatchTime()
        dispatchOrder2({ ...difyInfoJson, orderId: pengddingOrderRef.current }, true)
            .then((res) => {
                handleRemoveMapOrderIcon()
                handleCloseChooseDispatchTypePopupVisible()
                setSuccessPopupVisible(true)

                let timeout = setTimeout(() => {
                    handleCloseSuccessPopup()
                    clearTimeout(timeout)
                }, 2000)
            })
            .catch((res) => {
                handleRemoveMapOrderIcon()
                handleCloseChooseDispatchTypePopupVisible()
            })
    }
    //清除自动派单计时
    const clearAutoDispatchTime = () => {
        setautoDispatchTimeIsCounting(false)
        setAutoDispatchTime(0)
    }

    //去手动派单
    const handleToHandDispatch = () => {
        setChooseDispatchTypePopupVisible(false)
        setStartPopupVisible(false)
        setDispatchPopupVisible(true)
        clearAutoDispatchTime()
    }

    //手动派单
    const handleHandDispatch = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                orderId: pengddingOrderRef.current,
            }
            dispatchOrder2(data, true)
                .then((res) => {
                    handleRemoveMapOrderIcon()
                    setDispatchPopupVisible(false)
                    setSuccessPopupVisible(true)

                    let timeout = setTimeout(() => {
                        handleCloseSuccessPopup()
                        clearTimeout(timeout)
                    }, 2000)
                })
                .catch((res) => {
                    handleRemoveMapOrderIcon()
                    setDispatchPopupVisible(false)
                })
        })
    }

    //打开派单类型弹窗
    const handleOpenChooseDispatchTypeVisible = async (answerJson) => {
        //设置读秒数开始读秒
        const { teamId } = answerJson
        const { organName, id: organId } = getUserOrgan()
        try {
            let teamInfo = await getTeamOne(teamId)
            setStartPopupVisible(false)
            setChooseDispatchTypePopupVisible(true)
            if (teamInfo.retCode !== 200) {
                handleCloseDispatchPopupVisible()
                return message.error(teamInfo.retMsg)
            }
            const { teamName } = teamInfo.retData
            setAutoDispatchTime(defaultAutoDispatchTime)
            setautoDispatchTimeIsCounting(true)
            setFifyInfoJson({ ...answerJson, teamName, organName })
        } catch (error) {
            message.error('error: ', error)
            handleCloseDispatchPopupVisible()
        }
    }

    // 关闭派单成功弹窗
    const handleCloseSuccessPopup = () => {
        setSuccessPopupVisible(false)
        closeProcess()
        //清除地图marker
    }

    //关闭主弹窗
    const handleCloseStartPopupVisible = () => {
        setStartPopupVisible(false)
        closeProcess()
    }

    //关闭选择派单类型弹窗
    const handleCloseChooseDispatchTypePopupVisible = () => {
        setChooseDispatchTypePopupVisible(false)
        closeProcess()
        clearAutoDispatchTime()
    }

    //关闭派单弹窗
    const handleCloseDispatchPopupVisible = () => {
        setDispatchPopupVisible(false)
        closeProcess()
    }

    //关闭当前流程
    const closeProcess = () => {
        handleRemovePenddingOrders(pengddingOrderRef.current)
        pengddingOrderRef.current = ''
        setIsHasPengddingOrderProcess(false)
        fetchEventSourceReq.abort()
    }

    //移除地图icon
    const handleRemoveMapOrderIcon = () => {
        setRemoveMarkerPengddingOrder(pengddingOrderRef.current)
    }

    const fetchDify = async (id) => {
        // 拼接的数据
        let messageStr = ''
        let jsonStr = ''
        //是否是隐藏内容 如果```包裹，用于判断
        let isHind = false

        fetchEventSource(`${API_SERVER_HOST}/yh-ai-server/chat/backend/chat-messages`, {
            method: 'POST',
            headers: {
                // Authorization: getDifyAuthorization(),
                Authorization: getToken(),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                inputs: {
                    orderId: id,
                },
                query: '分析',
                response_mode: 'streaming',
                conversation_id: '',
                app_code: 'ci-order-dispatch',
            }),
            signal: fetchEventSourceReq.signal,
            openWhenHidden: true,
            onopen(response) {
                //判断接口有没有正常返回
                if (response.status !== 200) {
                    messageStr = `接口${response.status}`
                }
            },
            onmessage(event) {
                try{
                    let d
                    if (event && event.data) {
                        d = JSON.parse(event?.data)
                        if (d.event === 'message') {
                            // 根据```判断是消息还是json数据
                            if (d.answer.includes('```')) {
                                //json标识
                                isHind = !isHind
                                let arr = d.answer.split('```')
                                messageStr += arr[0] || ''
                                changeDifyInfo({ data: messageStr, conversation_id: d.conversation_id, loading: true }, id)
                                jsonStr += arr[1] || ''
                                return
                            }
                            if (!isHind) {
                                messageStr += d.answer
                                changeDifyInfo({ data: messageStr, conversation_id: d.conversation_id, loading: true }, id)
                            } else {
                                jsonStr += d.answer
                            }

                        }
                    }
                }catch(error){
                    console.log('onmessage--Error:', error)
                    handleCloseStartPopupVisible()
                    handleToHandDispatch()
                }
               
            },
            onerror(error) {
                console.log('onerror--Error:', error)
                handleCloseStartPopupVisible()
                handleToHandDispatch()
                throw error
            },
            onclose() {
                let json = null
                try {
                    if (jsonStr) {
                        json = JSON.parse(jsonStr.replace('json', ''))
                    }
                } catch (err) {
                    handleCloseStartPopupVisible()
                    handleToHandDispatch()
                    console.error('json格式化失败')
                }
                console.log(messageStr)
                console.log(json)
                changeDifyInfo({ data: messageStr, answerJson: json, loading: false }, id)
            },
        })
    }

    return {
        penddingOrders,
        isHasPengddingOrderProce,
        setIsHasPengddingOrderProcess,
        handleAddPenddingOrders,
        handleRemovePenddingOrders,
        getOrderDetail,
        startPopupVisible,
        dispatchPopupVisible,
        successPopupVisible,
        chooseDispatchTypePopupVisible,
        difyInfoMsg,
        difyLoading,
        handleToHandDispatch,
        autoDispatchTime,
        handleToAutoDispatch,
        handleHandDispatch,
        handleCloseStartPopupVisible,
        handleCloseDispatchPopupVisible,
        handleCloseSuccessPopup,
        handleMapOrderIconClick,
        removeMarkerPengddingOrder,
        setRemoveMarkerPengddingOrder,
        handleRemoveMapOrderIcon,
        handleCloseChooseDispatchTypePopupVisible,
        difyInfoJson,
    }
})
