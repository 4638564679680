import axios from "axios"
import {
    API_SERVER_HOST,
    API_SERVER_PORT
} from "../config/app"
import {
    message
} from "antd"
import {
    getToken,
    setToken
} from "./dataStorage"
import storage from "good-storage"
import moment from 'moment';

class AxiosInst {
    inst
    url
    errorIgnore
    constructor() {
        this.inst = axios.create({
            baseURL: API_SERVER_PORT ? `${API_SERVER_HOST}:${API_SERVER_PORT}` : `${API_SERVER_HOST}`,
            timeout: 1000 * 30,
        })
        this.url = ""
        this.init()
    }

    getInst() {
        return this.inst
    }

    init() {
        // this.inst.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        this.inst.interceptors.request.use(
            (config) => {
                this.url = config.url
                this.errorIgnore = config.errorIgnore
                const token = getToken()
                if (token) {
                    config.headers.Authorization = getToken()
                }
                return config
            },
            (error) => {
                console.log(error)
            }
        )

        this.inst.interceptors.response.use(
            (res) => {
                if (res.headers.authorization) {
                    setToken(res.headers.authorization)
                    // sessionStorage.setItem(TOKEN_KEY, res.headers.authorization);
                } else {
                    if (res.data && res.data.authorization) {
                        setToken(res.headers.authorization)
                        // sessionStorage.setItem(TOKEN_KEY, res.data.authorization);
                    }
                }
                if (res.status === 200) {
                    let data = res.data
                    if (data.retCode === 200) {
                        return Promise.resolve(data)
                    } else {
                        !this.errorIgnore && message.error(data.retMsg)
                        return Promise.reject(data)
                    }
                } else {
                    this.errorHandle(res)
                    return Promise.reject(res.data)
                }
            },
            (error) => {
                // const {
                //     response
                // } = error
                // if (this.errorIgnore) {
                //     return Promise.reject(response.data)
                // }
                // if (response) {
                //     message.error(`系统异常${response.status}`)
                //     // 请求已发出，但是不在2xx的范围
                //     this.errorHandle(response)
                //     return Promise.reject(response.data)
                // } else {
                //     message.error("服务器响应超时，请尝试重新请求")
                //     let errUrls = storage.get("overtime_url") || []
                //     errUrls.unshift(this.url)
                //     storage.set("overtime_url", errUrls.slice(0, 100))
                // }
                let res = error.response
                let addErr = { url: this.url, time: moment().format('YYYY-MM-DD HH:mm:ss'), }
                if (res) {
                	addErr.data = res.config.data
					addErr.code = res.status
                    let retData = res.data 
                    !this.errorIgnore && message.error(`code:${res.status} msg:${retData.retMsg} retData:${retData.retData}`)
                } else {
                    !this.errorIgnore && message.error('服务器响应超时，请尝试重新请求')
                    addErr.code = 'not response'
                }
                let requestErrData = storage.get('requestErrData') || []
                requestErrData.unshift(addErr)
                storage.set('requestErrData', requestErrData.slice(0, 50))
            }
        )
    }

    errorHandle(res) {
        // 状态码判断
        switch (res.status) {
            case 401:
                console.log("认证错误")
                message.error("token认证过期，请重新登陆")
                break
            case 403:
                console.log("没有权限访问资源")
                break
            case 404:
                console.log("请求的资源不存在")
                break
            case 500:
                console.log("请求的资源不存在")
                // message.error(res.retMsg)
                break
            default:
                console.log("连接错误")
        }
    }
}

export let axiosInst = new AxiosInst().getInst()

export default AxiosInst