import React, { FC, useState } from 'react'
import { YHModal } from '@/erp_subpackage/components/YHModal'
import { usePurchaseDrDetailsModel } from './purchaseDrDetailsModel'
import { YhBox } from '@/erp_subpackage/components/YhBox'
import { Table, Select, message } from 'antd'
import { purchaseDrService } from "../../purchaseDrService";
import useGlobalModel from '@/erp_subpackage/model/globalModel'
import { warehouseService } from "@/erp_subpackage/pages/base/warehouse/warehouseService";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

export const BatchSelectWarehouseModal = (props) => {
    const { mode, batchSelectWarehouseModal, setBatchSelectWarehouseModal, batchSelectWarehouseList, selectedRowKeys } = props
    const { editList, setEditList, addList, setAddList } = usePurchaseDrDetailsModel()
    const {
        whDs,
        user: { shopId },
        setMaterialInfo
    } = useGlobalModel()

    const [shelfInfo, setShelfInfo] = useState([]) //记录选中的货位信息

    const onCancel = () => {
        setBatchSelectWarehouseModal(false)
        setShelfInfo([])
    }

    //货位列表 Api
    const shelfLoadData = async (whObj) => {
        // const searchParams = {
        //     shopId,
        //     warehouseId: whObj.id,
        //     warehouseCode: whObj.value,
        //     code: '临时货位',
        //     pageSize: 10,
        //     pageNum: 1,
        // }
        // let { retData: records } = await warehouseService.shelfAllPage(searchParams)
        // if (!records || !records.length) {
        //     message.warning('该仓库下没有临时货位，请联系后台人员添加')
        //     setShelfInfo([])
        //     return
        // }
        // setShelfInfo(records[0])
        let dataSource= mode === 'edit' ? [...editList] : [...addList]
        let newList=[]
        dataSource.map((item)=>{
            if(!item.inWarehouseCode||!item.inWarehouseName||!item.inShelfCode){
                newList.push({
                    shopId,
                    materialCode:item.materialCode ,
                    warehouseCode: whObj.value
                })
            }
        })
        let { retData } = await purchaseDrService.findNewShelfCode(newList);
		if(retData.length>0){
            let newData=[]
            retData.map((list)=>newData.push({
                materialCode:list.materialCode,
                inWarehouseCode:whObj.value,
                inWarehouseName:whObj.label,
                inShelfCode:list.shelfCode
            }))
            setShelfInfo(newData)
        }
        else{
            message.warning('该仓库下没有临时货位，请联系后台人员添加')
            setShelfInfo([])
        }
    }

    const onSubmit = () => {
        if (shelfInfo.length<1) {
            message.warning('请选择仓库或者该仓库没有临时货位')
            return
        }
        let dataSource = mode === 'edit' ? [...editList] : [...addList]
        let newList=[]
        dataSource.map((item)=>{
            if(!item.inWarehouseCode||!item.inWarehouseName||!item.inShelfCode){
                let newData=shelfInfo?.find((list)=>list?.materialCode==item?.materialCode)
                if(newData){
                    newList.push({
                        ...item,
                        inWarehouseCode:newData.inWarehouseCode,
                        inWarehouseName:newData.inWarehouseName,
                        inShelfCode:newData.inShelfCode
                    })
                }else{
                    newList.push({
                        ...item,
                        inWarehouseCode:shelfInfo[0].inWarehouseCode,
                        inWarehouseName:shelfInfo[0].inWarehouseName,
                        inShelfCode:'临时货位'
                    })
                }
            }else{
                newList.push(item)
            }
        })
        mode === 'edit' ? setEditList(newList) : setAddList(newList)
        onCancel()
    }

    const columns = [
        {
            title: '序号',
            width: 40,
            align: 'center',
            render: (t, r, i) => i + 1,
        },
        {
            title: '商品编号',
            align: 'center',
            dataIndex: 'materialCode',
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        },
        {
            title: '商品名称',
            align: 'center',
            dataIndex: 'materialName',
        },
    ]

    const rightChildren = (
        <div className="flex justify-center translateX-70">
            <span className="label-name">仓库：</span>
            <Select
                style={{ width: 260 }}
                options={whDs}
                placeholder="请选择仓库"
                onChange={(value, option) => {
                    value && shelfLoadData(option)
                }}
            />
        </div>
    )

    return (
        <YHModal visible={batchSelectWarehouseModal} onOk={onSubmit} onCancel={onCancel} title="批量选择仓库" width={800} bodyHeight={500} showFooter={true}>
            <YhBox title={rightChildren} style={{ marginBottom: '15px' }}>
                <Table columns={columns} dataSource={batchSelectWarehouseList} pagination={false} scroll={{ x: 'max-content', y: 400 }} />
            </YhBox>
        </YHModal>
    )
}
